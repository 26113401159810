import { apiv2, HTTPV1DOT2 } from "@/axios.js"

apiv2.defaults.timeout = 600000

export default {
    async getEventById(eventId) {
        try {
            let response = await apiv2.get(`events/${eventId}/info`)
            if (response["data"]) {
                return response["data"]["data"]
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    },
    async getEventListAll() {
        let response = await apiv2
            .get(`events/all`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getEventListByOwner(email) {
        let response = await apiv2
            .get(`events/owners/${email}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getCollaboratorRole(eventId, email) {
        try {
            let response = await apiv2.get(`events/${eventId}/collaborators/${email}/role`)
            if (response["status"]) {
                return response["data"]
            } else {
                return false
            }
        } catch (error) {
            console.log(`err-in-getCollaboratorRole:msg-:${error["message"]}`)
        }
    },
    async getStudentStatus(eventId) {
        try {
            let response = await apiv2.get(`events/${eventId}/student/live`)
            if (response["data"]["status"]) {
                return response["data"]["data"]
            } else {
                return false
            }
        } catch (error) { 
            console.log(`err-in-getStudentStatus:msg-:${error["message"]}`)
        }
    },
    async getEventAvailableById(eventId) {
        try {
            let response = await apiv2.get(`events/available/${eventId}/event`)
            if (response["data"]["status"]) {
                return response["data"]["data"]
            } else {
                return false
            }
        } catch (error) {
            console.log("error ", error.message)
            return false
        }
    },
    async deleteEventAvailable(eventId) {
        try {
            let response = await apiv2.delete(`events/available/${eventId}/delete`)
            if (response["data"]["status"]) {
                return response["data"]["data"]
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    },
    async createEventAvailable(eventId) {
        try {
            let response = await apiv2.post(`events/available/${eventId}/create`)
            if (response["data"]["status"]) {
                return response["data"]
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    },
    async addStudents(eventid, data) {
        var response = await apiv2
            .post(`events/${eventid}/student/live`, data)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getLocationOfEvent(eventid) {
        try {
            let response = await apiv2.get(`events/${eventid}/location`)
            if (response["data"]["status"]) {
                return response["data"]
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    },
    async getStudentReport(eventId) {
        let response = await HTTPV1DOT2.get(`events/${eventId}/list/leave`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getStudentReportEachUser(eventId, email) {
        let response = await HTTPV1DOT2.get(`events/leave/user?eventid=${eventId}&email=${email}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getSection(courseCode, courseSection, term) {
        let response = await HTTPV1DOT2.get(`reg/detailofcourse?courseid=${courseCode}&semester=${term}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getQuiz(service, courseId) {
        let response = await HTTPV1DOT2.get(`lms/${service}/courses/${courseId}/quizzes`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getAssignment(service, courseId) {
        let response = await HTTPV1DOT2.get(`lms/${service}/courses/${courseId}/assignments`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async createEvent(data) {
        var response = await HTTPV1DOT2.post(`events/create`, data, { timeout: 600000 })
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async deleteEvent(eventId) {
        var response = await HTTPV1DOT2.delete(`events/${eventId}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getStudentPhoto(studentId) {
        try {
            const response = await HTTPV1DOT2.get(`mis/photo/student?studentid=${studentId}`, { responseType: "arraybuffer" })
            console.log("getStudenPhoto(API):", response["data"])
            response["data"] = Buffer.from(response.data).toString("base64")
            if (response["data"]) {
                return response["data"]
            }
            return response
        } catch (error) {
            console.log(error)
        }
    },
    async getStudentProfile(email) {
        try {
            const response = await HTTPV1DOT2.get(`students/${email}/profile`)
            return response["data"]
        } catch (error) {
            console.log(error)
        }
    },
    async patchStudentsInEvents(eventId) {
        let response = await apiv2
            .patch(`events/${eventId}/syncstudent`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async createUserManual(data) {
        console.log(data)
        var response = await HTTPV1DOT2.post(`events/usermanually/create`, data, { timeout: 600000 })
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getUserRole(email) {
        let response = await HTTPV1DOT2.get(`report/account?email=${email}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getFacultyReport(facultyCode) {
        let response = await HTTPV1DOT2.get(`report/all/with/faculty?faculty=${facultyCode}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getStudentListWithStatus(eventId, status) {
        let response = await HTTPV1DOT2.get(`report/student/status?reportid=${eventId}&status=${status}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async createEventReport(data) {
        console.log(data)
        var response = await HTTPV1DOT2.post(`report/create`, data, { timeout: 600000 })
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getReportWithEventId(eventId) {
        let response = await HTTPV1DOT2.get(`report/of/event?eventid=${eventId}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async deleteReport(reportId) {
        console.log(reportId)
        var response = await HTTPV1DOT2.delete(`report?reportid=${reportId}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async updateStudentReportStatus(reportId, email, status) {
        let response = await HTTPV1DOT2.put(`report/student/manual?reportid=${reportId}&email=${email}&status=${status}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async updateEventReport(data) {
        console.log(data)
        var response = await HTTPV1DOT2.put(`report/update`, data, { timeout: 600000 })
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getUserFormSseMsg(eventId, cmuAcc) {
        let response = await HTTPV1DOT2.get(`events/student/update?eventid=${eventId}&cmuitaccount=${cmuAcc}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async deleteStudents(eventId, cmuItAccount) {
        console.log(eventId)
        var response = await HTTPV1DOT2.delete(`delete/student/${eventId}/${cmuItAccount}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getExamCourseByCourseCode(courseCode) {
        let response = await HTTPV1DOT2.get(`lms/course/bycourseid?courseid=${courseCode}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getCollaboratorListByUser(email) {
        let response = await HTTPV1DOT2.get(`events/collaborators/${email}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getCollaboratorListByEventId(eventId) {
        let response = await HTTPV1DOT2.get(`events/${eventId}/collaborator`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async createCollaboratorRole(data) {
        console.log(data)
        var response = await HTTPV1DOT2.post(`events/${data.eventid}/collaborators/${data.email}/create`, data, { timeout: 600000 })
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async deleteCollaboratorRole(eventId, cmuItAccount) {
        console.log(eventId)
        var response = await HTTPV1DOT2.delete(`delete/collaborator/${eventId}/${cmuItAccount}`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async updateCheckInTime(data) {
        console.log(data)
        var response = await HTTPV1DOT2.put(`events/update/checkinTime`, data)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async updateEventTime(data) {
        console.log(data)
        var response = await HTTPV1DOT2.put(`events/update/event/duetime`, data)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async updateZoom(data) {
        console.log(data)
        try {
            const response = await HTTPV1DOT2.put(`/events/update/event/zoom`, data)
            if (response["data"]) {
                return response["data"]
            }
        } catch (error) {
            console.log(error)
        }
    },
    async updateStudentFinishStatus(cmuitacc, evenid, data) {
        var response = await HTTPV1DOT2.put(`events/update/finished/${cmuitacc}/event/${evenid}`, data)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getExamStudentGroup(service, courseId) {
        let response = await HTTPV1DOT2.get(`lms/${service}/courses/${courseId}/group`)
            .then((res) => {
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getLmsListOfUser(service, userid) {
        try {
            let response = await apiv2.get(`lms/${service}/course/all/users/${userid}`)
            if (response["data"]) {
                return response["data"]
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    },
    async getReportXlsxByEventId(eventId) {
        try {
            let response = await apiv2.get(`report/events/${eventId}/all`)
            if (response["data"]["status"]) {
                return response["data"]
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    },
    async createReportXlsx(eventId) {
        try {
            let response = await apiv2.post(`report/events/${eventId}/all`, { timeout: 600000 })
            if (response["data"]["status"]) {
                return response["data"]
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    },
    async checkZoomCMUConfig(meetingId) {
        try {
            let response = await apiv2.get(`events/zoom/meetings/${meetingId}/config`)
            if (response["data"]["status"]) {
                return response["data"]
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    },
}